<!-------------------------------------------------
description:  运维中心 设备运维 设备报文
/**
 *@author xiaosanye 
 *@date 2023/2/27
*/
--------------------------------------------------->
<template>
  <div class="page-container">
    <custom-list
      warm-tips="设备报文默认查询当天日志报文信息，如有需要请定期导出保存"
      :search-data="searchData"
      :search-params="searchParams"
      :show-header="true"
      table-title="报文列表"
      :table-columns="tableColumns"
      :table-operate-buttons="tableOperateButtons.filter(item => item.enabled())"
      @clear-form="handleSearchClean"
      :request-method="omManagementApi.maintenanceQuery"
      :header-buttons="headerButtons.filter(item => item.enabled())" />

    <ykc-operation-dialog
      title="报文详情"
      :showFooter="false"
      :on-close="onCloseDialog"
      :show.sync="showDialog">
      <div slot="content">
        <ykc-form ref="increaseForm">
          <ykc-form-item label="原始报文">
            <ykc-input type="textarea" v-model="rowDetail.msg" readonly></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="解析数据">
            <ykc-input type="textarea" v-model="rowDetail.data" readonly></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </div>
    </ykc-operation-dialog>
  </div>
</template>

<script>
  import CustomList from '@/components/biz/CustomList.vue';
  import { omManagementApi } from '@/service/apis';
  import { offlineExport, code, getLastTime, localGetItem } from '@/utils';

  export default {
    name: 'DeviceMessageList',
    components: { CustomList },
    data() {
      return {
        omManagementApi,
        searchParams: {
          timePeriod: [getLastTime('yyyy-MM-dd', 0), getLastTime('yyyy-MM-dd', 0)],
          startTime: getLastTime('yyyy-MM-dd', 0),
          endTime: getLastTime('yyyy-MM-dd', 0),
        },
        showDialog: false,
        rowDetail: {},
        //  0= 发送方=平台 接收方=充电设备  1= 发送方=充电设备 接收方=平台
        senderDictionary: [
          { name: '平台', id: 0 },
          { name: '充电设备', id: 1 },
        ],
        receiverDictionary: [
          { name: '充电设备', id: 0 },
          { name: '平台', id: 1 },
        ],
        tableColumns: [
          {
            label: '终端编码',
            prop: 'terminal',
            scopedSlots: { defaultTitle: () => '' },
            width: '200px',
          },
          {
            label: '报文指令',
            prop: 'type',
            width: '150px',
          },
          {
            label: '报文描述',
            prop: 'typeName',
            scopedSlots: { defaultTitle: () => '' },
            width: '280px',
          },
          {
            label: '事件时间',
            prop: 'actionTime',
            scopedSlots: { defaultTitle: () => '' },
            width: '240px',
          },
          {
            label: '发送方',
            prop: 'sender',
            width: '200px',
          },
          {
            label: '接收方',
            prop: 'receiver',
            width: '200px',
          },
          {
            label: '客户端ip',
            prop: 'clientIp',
            scopedSlots: { defaultTitle: () => '' },
            width: '200px',
          },
          {
            label: '服务端ip',
            prop: 'severIp',
            scopedSlots: { defaultTitle: () => '' },
            width: '200px',
          },
          // {
          //   label: '限制站点',
          //   prop: 'stationInfoVOList',
          //   title: false,
          //   scopedSlots: {
          //     defaultTitle: () => '',
          //     default: ({ row }) => {
          //       const { stationInfoVOList } = row;
          //       const reducer = (accumulator, currentValue) => `${accumulator} ${currentValue}`;
          //       const labels = stationInfoVOList?.map(item => item.stationName).reduce(reducer);
          //       return (
          //         <el-tooltip effect="dark" content={labels} placement="top-start">
          //           <span>{labels}</span>
          //         </el-tooltip>
          //       );
          //     },
          //   },
          // },
          // {
          //   label: '状态',
          //   prop: 'status',
          //   scopedSlots: {
          //     defaultTitle: () => '',
          //     default: ({ row }) => {
          //       const title =
          //         this.dictionary?.find(o => Number(o.id) === Number(row.status))?.name ?? '——';
          //       return <span>{title}</span>;
          //     },
          //   },
          // },
        ],

        tableOperateButtons: [
          // {
          //   render: (h, button, { row }) => {
          //     return (
          //       <ykc-button
          //         type="text"
          //         class="do-enable-btn"
          //         onClick={() => {
          //           // this.doEdit(row);
          //           this.currentRow = { ...row };
          //           this.show2 = true;
          //         }}>
          //         编辑
          //       </ykc-button>
          //     );
          //   },
          // },
          {
            enabled: () => code('finance:reconci:cus:sub:detail'),
            id: '1',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.showDialog = true;
              this.rowDetail = row;
            },
          },
          // {
          //   render: (h, button, { row }) => {
          //     // console.log('stop start btn row:', row);
          //     const oppositeTips = row.status === 1 ? '停用' : '启用';
          //     return (
          //       <ykc-button
          //         type="text"
          //         class="do-enable-btn"
          //         onClick={() => {
          //           this.doStartOrStop(row);
          //         }}>
          //         {oppositeTips}
          //       </ykc-button>
          //     );
          //   },
          // },
        ],
      };
    },
    methods: {
      onCloseDialog() {
        this.showDialog = false;
      },
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
        this.searchParams.startTime = event?.date[0];
        this.searchParams.endTime = event?.date[1];
      },
      handleSearchClean() {
        this.searchParams = {
          timePeriod: [getLastTime('yyyy-MM-dd', 0), getLastTime('yyyy-MM-dd', 0)],
          startTime: getLastTime('yyyy-MM-dd', 0),
          endTime: getLastTime('yyyy-MM-dd', 0),
        };
      },
    },
    computed: {
      headerButtons() {
        return [
          // {
          //   label: '通知规则',
          //   handle: (data, ctx) => {
          //     console.log('-> data', data);
          //     console.log('-> ctx', ctx);
          //   },
          // },
          // {
          //   label: '规则配置',
          //   handle: (data, ctx) => {
          //     console.log('-> data', data);
          //     console.log('-> ctx', ctx);
          //   },
          // },
          {
            enabled: () => code('maintain:alarm:msg:export'),
            label: '导出',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'message',
                  jsonNode: {
                    downloadKey: 'message',
                    ...ctx.searchParams,
                  },
                },
                ctx.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        console.log(getLastTime('yyyy-MM-dd', 7));
        return [
          {
            comName: 'YkcDatePicker',
            isDateType: false,
            key: 'timePeriod',
            change: this.handleChange,
            limitDate: getLastTime('yyyy-MM-dd', 8),
            label: '时间周期',
          },
          {
            comName: 'YkcInput',
            key: 'terminal',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            comName: 'YkcDropdown',
            key: 'type',
            label: '报文指令',
            placeholder: '请输入报文指令',
            configSet: {
              label: 'name',
              value: 'code',
            },
            data: localGetItem('dictionary')?.protocol_type || [],
          },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '充电站',
          //   placeholder: '请输入充电站',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '告警事项',
          //   placeholder: '请输入告警事项',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '告警码',
          //   placeholder: '请输入告警码',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '告警时间',
          //   placeholder: '请输入告警时间',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '平台响应动作',
          //   placeholder: '请输入平台响应动作',
          // },
          // {
          //   comName: 'YkcDropdown',
          //   key: 'status',
          //   label: '状态',
          //   placeholder: '请选择状态',
          //   data: [...this.dictionary],
          // },
        ];
      },
    },
  };
</script>

<style scoped></style>
